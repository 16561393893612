var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "flex-column",
    },
    [
      _c("sticky", { attrs: { className: "sub-navbar" } }, [
        _c(
          "div",
          { staticClass: "filter-container" },
          [
            _c("base-search", {
              staticStyle: { display: "inline-block" },
              attrs: { searchList: _vm.$ztglSearch, listQuery: _vm.listQuery },
              on: { search: _vm.handleFilter, "btn-event": _vm.onBtnClicked },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "app-container flex-item" },
        [
          _c(
            "el-row",
            { staticStyle: { height: "100%" }, attrs: { gutter: 8 } },
            [
              _c(
                "el-col",
                { staticStyle: { height: "100%" }, attrs: { span: 24 } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "bg-white",
                      staticStyle: { height: "100%" },
                      attrs: { id: "table_box_height" },
                    },
                    [
                      _c("basic-table", {
                        attrs: {
                          tableHeight: _vm.tableHeight,
                          tableHeaderList: _vm.firstHeaderList,
                          tableData: _vm.tableData,
                          listQuery: _vm.listQuery,
                          tableTotal: _vm.tableTotal,
                          hasOperation: true,
                        },
                        on: {
                          pagination: _vm.handleCurrentChange,
                          tableRowClick: _vm.tableRowClick,
                          tableColumnClick: _vm.tableColumnClick,
                          handleClickOperation: _vm.handleClickOperation,
                          handleClickShow: _vm.handleClickShow,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("general-form", {
        attrs: {
          FormDialog: _vm.FormDialog,
          FormList: _vm.FormList,
          title: _vm.addTitle,
          FormData: _vm.addform,
          isSpanAll: true,
        },
        on: {
          cancelDialog: _vm.cancelDialog,
          confirmDialog: _vm.confirmDialog,
        },
      }),
      _c("general-form", {
        attrs: {
          FormDialog: _vm.FormDialogEdit,
          FormList: _vm.FormList,
          title: _vm.EditTitle,
          FormData: _vm.modifyForm,
          isSpanAll: true,
        },
        on: {
          cancelDialog: _vm.cancelDialogEdit,
          confirmDialog: _vm.confirmDialogEdit,
        },
      }),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-mini user-dialog",
          attrs: {
            title: "组态图历史版本",
            visible: _vm.lctDialog,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.lctDialog = $event
            },
            close: function ($event) {
              _vm.lctDialog = false
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("basic-table", {
                attrs: {
                  tableHeight: 600,
                  tableHeaderList: _vm.lcttHeaderList,
                  tableData: _vm.lctTableData,
                  listQuery: _vm.listQueryLct,
                  hasOperation: true,
                  hasPagination: false,
                  isLct: true,
                },
                on: {
                  handleClickOperation: _vm.handleClickOperation,
                  btnYl: _vm.btnYl,
                  btnBj: _vm.btnBj,
                  btnSc: _vm.btnSc,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "success" },
                  on: { click: _vm.createNew },
                },
                [_vm._v("新建组态图")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.refeshChild },
                },
                [_vm._v("刷新")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.lctDialog = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }