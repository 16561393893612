<template>
  <div class="flex-column" v-loading="loading">
    <sticky :className="'sub-navbar'">
      <div class="filter-container">
        <base-search :searchList="$ztglSearch" :listQuery='listQuery' v-on:search='handleFilter' v-on:btn-event="onBtnClicked" style="display: inline-block;"></base-search>
      </div>
    </sticky>
    <div class="app-container flex-item">
      <el-row style="height: 100%;" :gutter="8" >
        <el-col :span="24" style="height: 100%;">
          <div class="bg-white" style="height: 100%;" id="table_box_height">
            <basic-table :tableHeight="tableHeight" :tableHeaderList="firstHeaderList" :tableData="tableData" :listQuery="listQuery" @pagination="handleCurrentChange" @tableRowClick="tableRowClick" @tableColumnClick="tableColumnClick" :tableTotal="tableTotal" :hasOperation="true" @handleClickOperation="handleClickOperation" @handleClickShow="handleClickShow"></basic-table >
          </div>
        </el-col>
      </el-row>
    </div>


    <!-- 添加弹窗 -->
    <general-form :FormDialog="FormDialog" :FormList="FormList" :title="addTitle" :FormData="addform" @cancelDialog="cancelDialog" @confirmDialog="confirmDialog" :isSpanAll="true"></general-form>

    <!-- 编辑弹窗 -->
    <general-form :FormDialog="FormDialogEdit" :FormList="FormList" :title="EditTitle" :FormData="modifyForm" @cancelDialog="cancelDialogEdit" @confirmDialog="confirmDialogEdit" :isSpanAll="true"></general-form>
    
    <!-- 组态图弹窗 -->
    <el-dialog class="dialog-mini user-dialog" title="组态图历史版本" :visible.sync="lctDialog" :width="'70%'" @close="lctDialog = false">
      <div>
        <basic-table :tableHeight="600" :tableHeaderList="lcttHeaderList" :tableData="lctTableData" :listQuery="listQueryLct" :hasOperation="true" @handleClickOperation="handleClickOperation" :hasPagination="false" :isLct="true" @btnYl="btnYl" @btnBj="btnBj" @btnSc="btnSc"></basic-table >
      </div>
      <div style="text-align:right;" slot="footer">
        <el-button size="small" type="success" @click="createNew">新建组态图</el-button>
        <el-button size="small" type="primary" @click="refeshChild">刷新</el-button>
        <el-button size="small" type="danger" @click="lctDialog = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
  import basicTable from './table'//表格组件
  import generalForm from '@/components/generalForm'//通用添加弹窗组件
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口

  
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import waves from '@/directive/waves' // 水波纹指令
  import Sticky from '@/components/Sticky'
  import elDragDialog from '@/directive/el-dragDialog'
  export default {
    name: 'hdybjzqgl',
    components: {
      Sticky,
      basicTable,
      generalForm
    },
    directives: {
      waves,
      elDragDialog
    },
    data() {
      return {
        loading:false,//遮罩
        jsonData:[//table数据
          {serialNumber:1,typeName:'DN15',manufacturer:'-',specifications:'-',hourFlow:'8.4',manufacturerAddress:'-',digitalDisplayDigit:'-',displayNum:'0',displayConfigure:'',remark:'',}
        ],
        listQuery: { // 查询条件
          page: 1,
          limit: 50,
          key: ''
        },
        tableHeaderList:this.$store.state.headerList,
        tableData:[],
        tableHeight:500,//表格高度
        CheckedKey:[],//选中数据的id集合
        tableChooseRow:{},//table选中数据
        FormDialog:false,//添加住户弹窗
        addform:{},//添加表单
        modifyForm:{},//编辑表单
        FormList:{//表格列
          column:[
            {columnKey:'projectName',columnDescription:'项目名称',columnType:'text',prop:'projectName',width:'120',placeholder:"项目名称",},
            {columnKey:'remark',columnDescription:'备注',columnType:'textarea',prop:'remark',width:'120',placeholder:"备注",},
          ],
          //校验
          rule:{
            projectName: [{ required: true, message: "项目名称不能为空", trigger: "blur" }, ],
          }
        },
        addTitle:'添加',//添加弹窗标题
        FormDialogEdit:false,//编辑表弹窗
        EditTitle:'编辑',//添加弹窗标题
        firstHeaderList:[],// 主列表列定义
        tableTotal:0,//明细条数
        lctDialog:false,//组态图弹窗
        lcttHeaderList:[],// 组态图列表列定义
        lctTableData:[],//组态图列表数据
        listQueryLct:{
          page: 1,
          limit: 999,
          key: ''
        },
        newPorjectId:'',//新建组态图项目id
      }
    },
    watch: {

    },
    computed: {
      
    },
    filters: {
      
    },
    created() {
      this.getList()
    },
    mounted() {
      // 计算table高度
      this.$nextTick(()=>{
        let ele=document.getElementById("table_box_height");
        this.tableHeight=ele.offsetHeight-50
        window.addEventListener('resize', () => { 
          let ele=document.getElementById("table_box_height");
          this.tableHeight=ele.offsetHeight-50
          this.$forceUpdate()
        });
        // 计算table高度
      })
    },
    methods: {
      // 按钮点击
      onBtnClicked: function(domId) {
        console.log('you click:' + domId)
        switch (domId) {
          case 'btnDel'://删除
            if(this.tableChooseRow.id){
              this.handleDelete(this.tableChooseRow)
            }else{
              this.$message({
                message: `请选择一条数据进行删除！`,
                type: 'error'
              })
            }
            break
          case 'btnAdd'://添加
            this.FormDialog = true
            break
          case 'btnEdit'://编辑
            if(this.tableChooseRow.id){
              this.modify(this.tableChooseRow)
            }else{
              this.$message({
                message: `请选择一条数据进行编辑！`,
                type: 'error'
              })
            }
            break
          default:
            break
        }
      },
      // 获取数据
      getList() {
        this.loading = true
        basicDataApi.ztglGetList(this.listQuery).then(response => {
          if(response.code == 200){
            response.columnHeaders.forEach((item) => { // 首字母小写
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.firstHeaderList = response.columnHeaders.filter(u => u.browsable && u.isList)
            this.firstHeaderList.forEach(i=>{
              if(i.key == 'projectName'){
                i.isClick = true
              }
            })
            this.tableData = response.data
            this.tableTotal = response.count
            this.loading = false
          }else{
            this.firstHeaderList = []
            this.tableData = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
            this.loading = false
          }
        }).catch(()=>{
          this.firstHeaderList = []
          this.tableData = []
          this.loading = false
        })

        // this.tableData = this.$store.state.tableDataSearch
      },
      // 搜索
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      // 切换分页
      handleCurrentChange(val) {
        this.tableChooseRow = {}//重置选中项
        this.listQuery.page = val.page
        this.listQuery.limit = val.limit
        this.getList()
      },
      // 行点击
      tableRowClick(row){
        this.tableChooseRow = row
      },
      //项目名称点击
      tableColumnClick(row){
        var id = row.releaseId
        var projectId = row.projectId
        // var url = process.env.VUE_APP_BASE_ZUTAI_URL  + 'draw/client_view.html?proid=' + projectId + '&id=' + id + '&userid=' + this.$store.state.userId + '&date=' + Date.now()
        var url = "https://app.qdhuade.com/www1/draw/client_view.html?proid=85e5f585-ec0c-49b2-99b5-4c77eb20d31f&id=93eb2c76-7822-e846-be30-9e84dcc30aa7&userid=00000000-0000-0000-0000-000000000000&date=1703144885355"
        window.open(url)
        // this.addform = row
        // this.FormDialogEdit = true
        // var id = row.id
        // var projectId = row.projectId
        // var url = process.env.VUE_APP_BASE_ZUTAI_URL  + 'draw/client_view.html?proid=' + projectId + '&id=' + id + '&userid=' + this.$store.state.userId + '&date=' + Date.now()
        // window.open(url)
      },
      // 多行删除
      handleDelete(row) {
        this.$confirm(`确定要删除项目名称为${row.projectName}的记录吗?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          cancelButtonClass: 'message-style',
          type: 'warning'
        }).then(() => {
          var data = [row.id]
          basicDataApi.ztglDelete(data).then(response => {
            if(response.code == 200){
              this.$notify({
                message: response.message,
                type: 'success',
                duration: 2000
              });
              this.getList()
              this.reset()//重置
            }else{
              this.$notify({
                message: response.message,
                type: 'warning',
                duration: 2000
              });
            }
          })
        }).catch(() => {});
      },
      // add添加取消
      cancelDialog(val){
        this.FormDialog = val
      },
      // add添加提交
      confirmDialog(){
        basicDataApi.ztglAdd(this.addform).then(response => {
          if(response.code == 200){
            this.$notify({
              message: response.message,
              type: 'success',
              duration: 2000
            });
            this.FormDialog = false
            this.getList()
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{
          this.FormDialog = false
        })
        this.reset()//重置
      },
      //重置
      reset(){
        this.addform = this.modifyForm ={
          projectName:'',
          remark:'',
        }
        this.tableChooseRow = {}
      },
      // 编辑表
      modify(row){
        this.modifyForm = JSON.parse(JSON.stringify(row))
        this.FormDialogEdit = true
      },
      // 编辑取消
      cancelDialogEdit(val){
        this.FormDialogEdit = val
      },
      // 编辑提交
      confirmDialogEdit(){
        basicDataApi.ztglModify(this.modifyForm).then(response => {
          if(response.code == 200){
            this.FormDialogEdit = false
            this.$notify({
              message: response.message,
              type: 'success',
              duration: 2000
            });
            this.getList()
            this.reset()//重置
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{
          this.FormDialogEdit = false
        })
      },
      // 点击操作-历史记录
      handleClickOperation(val){
        this.listQueryLct.key = val.id
        this.newPorjectId = val.id
        this.lctDialog = true
        basicDataApi.ztglGetHistoryList(this.listQueryLct).then(response => {
          if(response.code == 200){
            response.columnHeaders.forEach((item) => { // 首字母小写
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.lcttHeaderList = response.columnHeaders.filter(u => u.browsable && u.isList)
            this.lctTableData = response.data
          }else{
            this.lcttHeaderList = []
            this.lctTableData = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        })
      },
      // 点击操作查看
      handleClickShow(val){
        var id = val.releaseId
        var projectId = val.projectId
        var url = process.env.VUE_APP_BASE_ZUTAI_URL  + 'draw/client_view.html?proid=' + projectId + '&id=' + id + '&userid=' + this.$store.state.userId + '&date=' + Date.now()
        window.open(url)
      },
      // 点击操作 - 预览
      btnYl(val){
        var id = val.id
        var projectId = val.projectId
        var url = process.env.VUE_APP_BASE_ZUTAI_URL  + 'draw/client_view.html?proid=' + projectId + '&id=' + id + '&userid=' + this.$store.state.userId + '&date=' + Date.now()
        window.open(url)
      },
      // 点击操作 - 编辑
      btnBj(val){
        var id = val.id
        var projectId = val.projectId
        var url = process.env.VUE_APP_BASE_ZUTAI_URL  + 'draw/index_edit.html?proid=' + projectId + '&id=' + id + '&userid=' + this.$store.state.userId + '&date=' + Date.now()
        window.open(url)
      },
      // 点击操作 - 删除
      btnSc(val){
        this.$confirm(`确定要删除吗?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          cancelButtonClass: 'message-style',
          type: 'warning'
        }).then(() => {
          basicDataApi.ztglHistoryDelete([val.id]).then(response => {
            if(response.code == 200){
              this.$notify({
                message: response.message,
                type: 'success',
                duration: 2000
              });
              this.refeshChild()
            }else{
              this.$notify({
                message: response.message,
                type: 'warning',
                duration: 2000
              });
            }
          })
        }).catch(() => {});
      },
      //刷新子表
      refeshChild(){
        basicDataApi.ztglGetHistoryList(this.listQueryLct).then(response => {
          if(response.code == 200){
            response.columnHeaders.forEach((item) => { // 首字母小写
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.lcttHeaderList = response.columnHeaders.filter(u => u.browsable && u.isList)
            this.lctTableData = response.data
          }else{
            this.lcttHeaderList = []
            this.lctTableData = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        })
      },
      //新建组态图
      createNew(){
        var id = (this.generateGUID() + this.generateGUID() + "-" + this.generateGUID() + "-" + this.generateGUID() + "-" + this.generateGUID() + "-" + this.generateGUID() + this.generateGUID() + this.generateGUID());
        var projectId = this.newPorjectId
        var url = process.env.VUE_APP_BASE_ZUTAI_URL  + 'draw/index_edit.html?proid=' + projectId + '&id=' + id + '&userid=' + this.$store.state.userId + '&date=' + Date.now()
        window.open(url)
      },
      // 生成GUID随机数
      generateGUID(){
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      },
      
      
      
    },
  }

</script>

<style lang="scss" scoped>
  .add_class .el-form-item{
    width: 50%;
    float: left;
  }
</style>
